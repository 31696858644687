<template>
  <v-dialog
    v-model="mapVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <v-spacer />
        <span> Salve a sua localização </span>
        <v-spacer />
        <v-btn @click="$emit('close')" fab fixed right depressed color="white">
            <v-icon large>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-1">
        <p class="text-center">
          Selecione o seu local no mapa ou utilize o botão "Meu local".
        </p>
      </v-card-text>

      <v-container>
        <v-card-actions style="margin-right: -8px">
          <v-spacer />
          <v-btn
            :loading="loading"
            @click="getLocal"
            color="light-blue"
            small
            text
          >
            <v-icon small left> mdi-crosshairs-gps </v-icon>
            Meu local
          </v-btn>
          <v-spacer />
        </v-card-actions>

        <map-view
          :next-position.sync="nextPosition"
          :position="position"
          class="map-view-modal"
          @onChangePosition="$emit('onChangePosition', $event)"
        />

        <v-card-actions :style="bottomStyle">
          <v-btn @click="saveClick" color="light-blue" style="color: white" block> Salvar </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import MapView from "./MapView";

export default {
  name: "map-view-modal",
  components: { MapView },
  props: ['visible'],

  data: () => ({
    mapVisible: false,
    loading: false,
    position: null,
    nextPosition: null,
  }),

  created() {
      this.mapVisible = this.visible;
    // this.nudgeTimeoutId = -1;
    // this.$events.$on("map-view-modal", this.handleEvent);
  },
  beforeDestroy() {
    // this.$events.$off("map-view-modal", this.handleEvent);
  },

  computed: {
    ultraWide() {
      const { width, height, xsOnly } = this.$vuetify.breakpoint;
      const ratio = width / height;
      return xsOnly && ratio < 0.5;
    },

    bottomStyle() {
      const bottom = this.ultraWide ? "34px" : "20px";
      let mr = "0";
      if (this.$vuetify.breakpoint.mdAndUp) {
        mr = "32px";
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        mr = "64px";
      }

      return `
        bottom: ${bottom};
        flex-direction: row;
        left: 0;
        margin-right: ${mr};
        position: fixed;
        right: 0;
      `;
    },
  },

  watch: {
    visible(v) {
    //   this.$nextTick(() => this.$store.commit("map-view-modal", v));
      setTimeout(() => {
        this.mapVisible = v;
      }, 100);
    },
  },

  methods: {
    handleEvent(e) {
      if (!e) {
        this.mapVisible = false;
      } else {
        this.position = e.position;
        this.nextPosition = null;
        this.mapVisible = true;
        this.positionCallback = e.positionCallback;
      }
    },

    getLocal() {
      this.nudgeTimeoutId = setTimeout(this.showNudgeBanner, 8 * 1000);
      this.geoOptions = {
        maximumAge: 60 * 1000,
        timeout: 15 * 1000,
      };
      this.getCurrentPosition();
    },

    geoSuccess(position) {
      this.loading = false;
      clearTimeout(this.nudgeTimeoutId);
      this.position = this.getCoords(position.coords);
    //   console.log(this.position);
    },

    geoError(error) {
      this.loading = false;
      clearTimeout(this.nudgeTimeoutId);

      switch (error.code) {
        case error.TIMEOUT:
          this.notify('Verifique sua conexão com a internet', 'warning');
          break;
        case error.PERMISSION_DENIED:
          this.notify('Permissão para localização negada', 'warning');
          break;
        case error.POSITION_UNAVAILABLE:
          this.notify('Não foi possível obter sua posição no momento', 'warning');
          break;
        default:
          break;
      }
    },

    getCurrentPosition() {
      if (!this.isLocationSupported()) {
        return;
      }

      this.loading = true;
      navigator.geolocation.getCurrentPosition(
        this.geoSuccess,
        this.geoError,
        this.geoOptions
      );
    },

    getCoords(coords) {
      return {
        accuracy: coords.accuracy,
        altitude: coords.altitude,
        altitudeAccuracy: coords.altitudeAccuracy,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    },

    showNudgeBanner() {
    //   const onPress = () => {
    //     this.getCurrentPosition();
    //   };
    //   const t = [
    //     "Deseja utilizar sua localização atual",
    //     "para salvar como local de entrega?",
    //   ];

    //   this.$alert.show({
    //     text: t.join(" "),
    //     actions: [{ text: "Ativar", onPress }],
    //   });
    },

    isLocationSupported() {
      if (!navigator.geolocation) {
        this.notify('Localização não suportada neste dispositivo', 'warning');

        return false;
      }
      return true;
    },

    saveClick() {
      this.$emit('close');
      this.positionCallback?.(this.nextPosition || this.position);
    },
  },
};
</script>

<style scoped>
.icon-left {
  visibility: hidden;
}

.map-view-modal {
  margin: 10px 0;
}
</style>
